:root {
    --pixel-size: 2px;
    --grid-cell: calc( var(--pixel-size) * 16);
}

.pixel-art {
    image-rendering: pixelated;
}


.camera {
    overflow: hidden;
    background: #61ddf7;
    position:relative;
}

.map {
    image-rendering: pixelated;
    background-image: url("https://i.imgur.com/a993R8f.png");
    background-size: 100%;
    width: calc(150 * var(--grid-cell));
    height: calc(150 * var(--grid-cell));
    position: relative;
}

.character {
    width: calc( var(--grid-cell)* 2 );
    height: calc( var(--grid-cell)* 2 );
    position: absolute;
    overflow:hidden;
    border: 0;
 }

 .shadow {
    width: calc( var(--grid-cell)* 2 );
    height: calc( var(--grid-cell)* 2 );
    position: absolute;
    left:0;
    top:0;
    background: url("https://assets.codepen.io/21542/DemoRpgCharacterShadow.png") no-repeat no-repeat;
    background-size: 100%;
    border: 0;
 }

 .character_spritesheet {
    position: absolute;
    background: url("https://assets.codepen.io/21542/DemoRpgCharacter.png") no-repeat no-repeat;
    background-size: 100%;
    width: calc( var(--grid-cell)* 8 );
    height: calc( var(--grid-cell)* 8 );
    border: 0;
 }
 
 .character[facing="right"] .character_spritesheet {
    background-position-y: calc( var(--pixel-size) * -32 );
 }
 .character[facing="up"] .character_spritesheet {
    background-position-y: calc( var(--pixel-size) * -64 );
 }
 .character[facing="left"] .character_spritesheet {
    background-position-y: calc( var(--pixel-size) * -96 );
 }
 .character[walking="true"] .character_spritesheet {
    animation: walkAnimation 0.6s steps(4) infinite; 
 }

 .shot {
   transition: transform 2s;
 }
 
 @keyframes walkAnimation {
    from {
      transform: translate3d(0%,0%,0);
    }
    to {
      transform: translate3d(-100%,0%,0);
    }
  }
div {
   font-family: 'Play';
}